function renderDSAModal() {
	var styles = `<style>
			.dsa-modal {
				position:fixed;
				z-index:999;
				top:0;
				left:0;
				display:flex;
				justify-content:center;
				align-items:center;
				height:100%;
				width:100%;
				overflow:auto;
				padding:24px;
				font-size:16px;
				line-height:24px;
				font-family:"Cera Pro","LatoWeb",Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
				box-sizing:border-box;
			}
			.dsa-modal *{
				box-sizing:border-box;
				font-family:inherit;
			}
			.dsa-modal button{
				cursor:pointer;
				background:transparent;
				border:unset;
				padding:unset;
				margin:unset;
			}
			.dsa-modal button:disabled{
				cursor:not-allowed;
				pointer-events:none;
				opacity:0.5;
			}
			.dsa-modal h1{
				font-size:20px;
				margin:0;
				margin-bottom:24px;
				font-weight:bold;
				text-align:center;
			}
			.dsa-modal p{
				margin:0;
				margin-bottom:20px;
			}
			.dsa-modal a{
				color:#006FE3;
				text-decoration:none;
			}

			.dsa-modal .dsa-modal__content {
				background:#ffffff;
				border-radius:8px;
				padding:24px;
				width:100%;
				height:auto;
				max-height:100%;
				max-width:560px;
				overflow:auto;
				-webkit-overflow-scrolling: touch;
			}
			.dsa-modal .dsa-modal__overlay {
				background:rgba(10,30,50,0.8);
				width:100%;
				height:100%;
				position:absolute;
				top:0;
				left:0;
				z-index:-1;
			}
			.dsa-modal .dsa-modal__close{
				display:block;
				margin-left:auto;
				width:20px;
				height:20px;
				cursor:pointer;
				overflow:hidden;
				text-indent:-100px;
				position:relative;
				margin-bottom:24px;
			}
			.dsa-modal .dsa-modal__close:before, .dsa-modal .dsa-modal__close:after{
				content:'';
				display:block;
				width:100%;
				height:2px;
				background:#22242D;
				top:50%;
				position:absolute;
			}
			.dsa-modal .dsa-modal__close:before{
				transform:rotate(45deg);
			}
			.dsa-modal .dsa-modal__close:after{
				transform:rotate(-45deg);
			}
			.dsa-modal .dsa-modal__select-container{
				display:flex;
			}
			.dsa-modal .dsa-modal__footer{
				display:flex;
				flex-direction:column-reverse;
				gap:16px;
				margin-top:24px;
			}
			.dsa-modal .dsa-btn{
				width:100%;
				border-radius:8px;
				padding:4px 20px;
				color:#006FE3;
				border:2px solid #006FE3;
				line-height:24px;
				min-height:40px;
				display:flex;
				align-items:center;
				justify-content:center;
				font-size:16px;
			}
			.dsa-modal .dsa-btn--cta{
				background:#006FE3!important;
				color:#ffffff;
			}
			.dsa-modal .dsa-modal a.dsa-btn--cta{
				color:#ffffff;
			}
			.dsa-modal .dsa-modal__page{
				display:none;
			}
			.dsa-modal .dsa-modal__page--one {
				display:block;
			}

			.dsa-modal .dsa-modal__select-container{
				display:flex;
				gap:12px;
			}


			.dsa-modal .dsa-custom-checkbox{
				display:block;
				line-height:0;
				margin-top:3px;
			}
			.dsa-modal .dsa-custom-checkbox__checkbox{
				display:none;
			}
			.dsa-modal .dsa-custom-checkbox__checkbox:checked + .dsa-custom-checkbox__new-checkbox{
				background:#006FE3;
				color:#ffffff;
				border-color:#006FE3;
			}
			.dsa-modal .dsa-custom-checkbox__checkbox:checked + .dsa-custom-checkbox__new-checkbox:before{
				display: block;
				position: absolute;
				top: 50%;
				left: 5%;
				content: '';
				width: 50%;
				height: 3px;
				background: #ffffff;
				transform: rotate(45deg);
				border-radius: 2px;
			}
			.dsa-modal .dsa-custom-checkbox__checkbox:checked + .dsa-custom-checkbox__new-checkbox:after{
				display: block;
				position: absolute;
				top: 40%;
				right: 0;
				content: '';
				width: calc(100% - 3px);
				height: 3px;
				background: #ffffff;
				transform: rotate(-45deg);
				border-radius: 2px;
			}
			.dsa-modal .dsa-custom-checkbox__new-checkbox{
				display:inline-block;
				position:relative;
				width:18px;
				height:18px;
				border-radius:4px;
				background:#ffffff;
				border:2px solid #9192A1;
				cursor:pointer;
				line-height:1;
			}


			@media screen and (min-width: 480px) {
				.dsa-modal .dsa-modal__footer{
					flex-direction:row;
					align-items:center;
					justify-content:center;
				}
				.dsa-modal .dsa-btn{
					width:auto;
				}
				.dsa-modal h1{
					font-size:22px;
				}
			}

			@media screen and (min-width: 640px) {
				.dsa-modal h1{
					font-size:24px;
				}
			}
		</style>`;

	var modal = 
		`<div class="dsa-modal" id="js-dsa-modal">
			<div class="dsa-modal__overlay" id="js-dsa-overlay"></div>
			<div class="dsa-modal__content">
				<button class="dsa-modal__close" id="js-dsa-modal-close">X</button>
				<div class="dsa-modal__page dsa-modal__page--one">
					<h1>Digital Services Act Form</h1>
					<p><strong>Self Certification Compliance</strong></p>
					<p>The <a href="https://eur-lex.europa.eu/eli/reg/2022/2065/oj#:~:text=in%20that%20register%3B-,(e),or%20services%20that%20comply%20with%20the%20applicable%20rules%20of%20Union%20law.,-2.%C2%A0%C2%A0%C2%A0Upon%20receiving" target="_blank">Digital Services Act (DSA)</a> is a new EU regulation affecting global e-commerce platforms such as LEGO BrickLink. </p>
					<p>All BrickLink sellers are required to self-certify that they commit to only offer products or services that comply with the applicable rules of European Union law. You can make this self-certification by clicking the check box below. Sellers that have not completed this self-certification by February 16, 2025 will have their seller privileges suspended as of February 17, 2025 continuing until the self-certification is completed.</p>
					<p>Questions about DSA can be directed to our <a href="/helpDesk.asp" target="_blank">Help Desk</a>.</p>
					<label class="dsa-modal__select-container">
						<label class="dsa-custom-checkbox">
							<input id="js-dsa-checkbox" type="checkbox" class="dsa-custom-checkbox__checkbox"/>
							<span class="dsa-custom-checkbox__new-checkbox"></span>
						</label>
						<span>I commit to only offer products and/or services that comply with the applicable rules of EU law.</span>
					</label>
					<div class="dsa-modal__footer">
						<a class="dsa-btn" href="//${getHost("www")}/help.asp?helpID=2655" target="_blank">Learn more</a>
						<button class="dsa-btn dsa-btn--cta" id="js-dsa-submit" disabled>Submit</button>
					</div>
				</div>
				<div class="dsa-modal__page dsa-modal__page--two">
					<h1>Thank you!</h1>
					<p><strong>You’re all set!</strong></p>
					<p>Thank you for submitting your self-certification. Your self-certification has been noted and your seller privileges will continue without interruption.</p>
					<p>More information about DSA can be found on our <a href="//${getHost("www")}/help.asp?helpID=2655">Help Page</a>.</p>
					<div class="dsa-modal__footer">
						<a class="dsa-btn" href="/v2/mystore/display.page">My Store</a>
						<a class="dsa-btn dsa-btn--cta" href="/">Onward to BrickLink</a>
					</div>
				</div>
			</div>
		</div>`;
	
	var modalPastDeadline = 
		`<div class="dsa-modal" id="js-dsa-modal">
			<div class="dsa-modal__overlay" id="js-dsa-overlay"></div>
			<div class="dsa-modal__content">
				<button class="dsa-modal__close" id="js-dsa-modal-close">X</button>
				<div class="dsa-modal__page dsa-modal__page--one">
					<h1>Digital Services Act Form</h1>
					<p><strong>Self Certification Compliance</strong></p>
					<p>The <a href="https://eur-lex.europa.eu/eli/reg/2022/2065/oj#:~:text=in%20that%20register%3B-,(e),or%20services%20that%20comply%20with%20the%20applicable%20rules%20of%20Union%20law.,-2.%C2%A0%C2%A0%C2%A0Upon%20receiving" target="_blank">Digital Services Act (DSA)</a> is an EU regulation affecting global e-commerce platforms such as LEGO BrickLink. </p>
					<p>All BrickLink sellers who did not make the required self-certification by February 16, 2025 had their seller privileges suspended as required by the Digital Services Act.</p>
					<p>If you would like to have your seller privileges reinstated, please click the checkbox below to make the required self-certification. Once the self-certification has been received, your seller privileges will be reinstated.</p>
					<p>Additional questions about DSA can be directed to our <a href="/helpDesk.asp" target="_blank">Help Desk</a>.</p>
					<label class="dsa-modal__select-container">
						<label class="dsa-custom-checkbox">
							<input id="js-dsa-checkbox" type="checkbox" class="dsa-custom-checkbox__checkbox"/>
							<span class="dsa-custom-checkbox__new-checkbox"></span>
						</label>
						<span>I commit to only offer products and/or services that comply with the applicable rules of EU law.</span>
					</label>
					<div class="dsa-modal__footer">
						<a class="dsa-btn" href="//${getHost("www")}/help.asp?helpID=2655" target="_blank">Learn more</a>
						<button class="dsa-btn dsa-btn--cta" id="js-dsa-submit" disabled>Submit</button>
					</div>
				</div>
				<div class="dsa-modal__page dsa-modal__page--two">
					<h1>Thank you!</h1>
					<p><strong>You’re all set!</strong></p>
					<p>Thank you for submitting your self-certification. Your self-certification has been noted and your seller privileges will continue without interruption.</p>
					<p>More information about DSA can be found on our <a href="//${getHost("www")}/help.asp?helpID=2655">Help Page</a>.</p>
					<div class="dsa-modal__footer">
						<a class="dsa-btn" href="/v2/mystore/display.page">My Store</a>
						<a class="dsa-btn dsa-btn--cta" href="/">Onward to BrickLink</a>
					</div>
				</div>
			</div>
		</div>`;
	
	const today = returnEnv() === 'blapp' ? blapp.time.server_ts : new Date(blo_server_time).getTime();
	const deadline = new Date('2025-02-17').getTime();

	const isPastDeadline = today > deadline;
	
	const modalArea = document.createElement('div');
				modalArea.innerHTML += styles;
	if(isPastDeadline) {
		modalArea.innerHTML += modalPastDeadline;
	}else{
		modalArea.innerHTML += modal;
	}

	let showDsa;
	const isSeller = returnEnv() === 'blapp' ? blapp.session.me && blapp.session.me.store != null : parseInt(bl.session.user.user_type) > 1;
	const isLoggedIn = returnEnv() === 'blapp' ? blapp.session.isLoggedIn() : bl.session.is_loggedin;
	const isVerified = isSeller ? (returnEnv() === 'blapp' ? blapp.session.me.store.bIsVerified == true : !isSellerVerificationNeeded) : false;
	
	if(isLoggedIn && isSeller && isVerified) {
		showDsa = returnEnv() === 'blapp' ? blapp.session.me.store.bDSASelfCertificate == false : bl.session.user.user_dsa_self_certificate == false;
	}else{
		showDsa = false;
	}

	const isMyStorePage = window.location.pathname === '/orderReceived.asp';


	if(isLoggedIn && showDsa) {
		if(isPastDeadline) {
			if(isMyStorePage) {
				document.body.appendChild(modalArea);

				handleDSAModal();
			}
		}else{
			document.body.appendChild(modalArea);

			handleDSAModal();
		}
	}
}

function returnEnv() {
	return typeof blapp !== 'undefined' ? 'blapp' : 'bl';
}

function handleDSAModal() {
	var overlay = document.getElementById('js-dsa-overlay');
	var modal = document.getElementById('js-dsa-modal');
	var modalClose = document.getElementById('js-dsa-modal-close');
	var dsaCheckbox = document.getElementById('js-dsa-checkbox');
	var submitButton = document.getElementById('js-dsa-submit');

	overlay.addEventListener('click', function() {
		closeModal();
	});
	modalClose.addEventListener('click', function() {
		closeModal();
	});
	dsaCheckbox.addEventListener('change', function() {
		if(dsaCheckbox.checked) {
			submitButton.disabled = false;
		}else{
			submitButton.disabled = true;
		}
	});
	submitButton.addEventListener('click', async function() {
		submitButton.innerHTML = 'Submitting...';
		submitButton.disabled = true;

		const data = fetch(`//${getHost("www")}/ajax/renovate/mystore/dsa_self_certificate.ajax?self_certified=1`, {
			method: 'GET',
			credentials: 'include'
		}).then(response => {
			return response.json();
		}).catch(error => {
			console.error('Error:', error);
		});

		await data.then(function success(response) {
			if(response.returnCode == 0) {
				modal.querySelector('.dsa-modal__page--one').style.display = 'none';
				modal.querySelector('.dsa-modal__page--two').style.display = 'block';
			}else{
				console.error("DSA SUBMIT ERROR: " + response.errorTicket);

				triggerFail();
			}
		});
	});
}

function closeModal() {
	var modal = document.getElementById('js-dsa-modal');

	modal.remove();
}

function triggerFail() {
	var submitButton = document.getElementById('js-dsa-submit');

	submitButton.innerHTML = 'Failed to submit';
	submitButton.disabled = true;

	setTimeout(function() {
		submitButton.innerHTML = 'Submit';
		submitButton.disabled = false;
	}, 1000);
}

renderDSAModal();



function getHost(host) {
	if(window.hasOwnProperty("blapp")) {
		return blapp.config.getHost(host);
	}else if(window.hasOwnProperty("bl")) {
		return bl.util.getBLHost(host);
	}else{
		return host + ".bricklink.com";
	}
}

function getReportPageURL(reportArea, contentType, contentID, contentUrl) {
	let url = `//${getHost("www")}/v3/help/report_problem.page?reportArea=${reportArea}&contentType=${contentType}&contentId=${contentID}`;
	
	if(contentUrl) {
		url += "&contentUrl=" + (contentUrl == "self" ? encodeURIComponent(window.location.href) : contentUrl);
	}
	return url;
}

function gotoReportPage(reportArea, contentType, contentID, contentUrl) {
	const url = getReportPageURL(reportArea, contentType, contentID, contentUrl);

	window.open(url, "_blank");
}

window.gotoReportPage = gotoReportPage;